import { Suspense, lazy } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

// Lazy loaded components
const Home = lazy(() => import('@/components/Home'));
const SignUp = lazy(() => import('@/components/Auth/SignUp'));
const WithGoogle = lazy(() => import('@/components/Auth/WithGoogle'));
const Auth = lazy(() => import('@/components/Auth/Auth'));
const SignUpSuccess = lazy(() => import('@/components/Auth/SignUp/SignUpSuccess'));
const SignIn = lazy(() => import('@/components/Auth/SignIn/SignIn'));
const SignInWithEmail = lazy(() => import('@/components/Auth/SignIn/SignInWithEmail'));
const SignOut = lazy(() => import('@/components/Auth/SignOut'));
const WithFacebook = lazy(() => import('@/components/Auth/WithFacebook'));
const OauthRedirect = lazy(() => import('@/components/Auth/OauthRedirect'));
const ProtectedLayout = lazy(() => import('@/components/Auth/ProtectedLayout'));
const VerifyEmailAccount = lazy(
  () => import('@/components/Auth/SignUp/VerifyEmailAccount'),
);
const Memoory = lazy(() => import('@/components/Memoory/Memoory'));
const Profile = lazy(() => import('@/components/Memoory/Profile/Profile'));
const Admin = lazy(() => import('@/components/Memoory/Admin/Admin'));
const Episodes = lazy(() => import('@/components/Memoory/Profile/Episodes/Episodes'));
const Episode = lazy(() => import('@/components/Memoory/Profile/Episodes/Episode'));
const EpisodesOnboarding = lazy(
  () => import('@/components/Memoory/Profile/Episodes/Onboarding'),
);
const Dedications = lazy(
  () => import('@/components/Memoory/Profile/Dedications/Dedications'),
);

const ManageEpisodes = lazy(() => import('@/components/Memoory/Admin/Episodes/Episodes'));

const AppRouterProvider = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Suspense>
          <ProtectedLayout redirectPath="/auth/sign-in">
            <Home />
          </ProtectedLayout>
        </Suspense>
      ),
    },
    {
      path: '/memoories',
      element: (
        <Suspense>
          <ProtectedLayout redirectPath="/auth/sign-in">
            <Home />
          </ProtectedLayout>
        </Suspense>
      ),
    },
    {
      element: (
        <Suspense>
          <Memoory />
        </Suspense>
      ),
      children: [
        {
          element: <Navigate to="/memoories/:memooryId" replace />,
          path: 'memoories/:memooryId/profile',
        },
        {
          element: (
            <Suspense>
              <Profile />
            </Suspense>
          ),
          path: 'memoories/:memooryId',
          children: [
            {
              element: (
                <Suspense>
                  <Episodes />
                </Suspense>
              ),
              index: true,
            },
            {
              element: (
                <Suspense>
                  <Episodes />
                </Suspense>
              ),
              path: 'episodes',
            },
            {
              element: (
                <Suspense>
                  <Episode />
                </Suspense>
              ),
              path: 'episodes/:episodeId',
            },
            {
              element: (
                <Suspense>
                  <Dedications />
                </Suspense>
              ),
              path: 'dedications',
            },
            {
              element: (
                <Suspense>
                  <div className="flex items-center justify-center">Recuerdos</div>
                </Suspense>
              ),
              path: 'gallery',
            },
          ],
        },
        {
          element: (
            <Suspense>
              <Profile />
            </Suspense>
          ),
          path: 'memoories/onboarding',
          children: [
            {
              element: (
                <Suspense>
                  <EpisodesOnboarding />
                </Suspense>
              ),
              index: true,
            },
          ],
        },
        {
          element: (
            <Suspense>
              <ProtectedLayout redirectPath="/auth/sign-in">
                <Admin />
              </ProtectedLayout>
            </Suspense>
          ),
          path: 'memoories/:memooryId/admin',
          children: [
            {
              element: (
                <Suspense>
                  <ManageEpisodes />
                </Suspense>
              ),
              path: 'episodes',
            },
            {
              element: (
                <Suspense>
                  <div className="flex items-center justify-center">Invitados</div>
                </Suspense>
              ),
              path: 'invite',
            },
            {
              element: (
                <Suspense>
                  <div className="flex h-dvh items-center justify-center">
                    Participación
                  </div>
                </Suspense>
              ),
              path: 'participation',
            },
            {
              element: (
                <Suspense>
                  <div className="flex items-center justify-center"></div>
                </Suspense>
              ),
              path: 'arrange',
            },
          ],
        },
      ],
    },
    {
      element: (
        <Suspense>
          <Auth />
        </Suspense>
      ),
      children: [
        {
          path: 'auth/sign-in',
          element: (
            <Suspense>
              <SignIn />
            </Suspense>
          ),
        },
        {
          path: 'auth/sign-up',
          element: (
            <Suspense>
              <SignUp />
            </Suspense>
          ),
        },
        {
          path: 'auth/verify-account',
          element: (
            <Suspense>
              <VerifyEmailAccount />
            </Suspense>
          ),
        },
        {
          path: 'auth/sign-in/email',
          element: (
            <Suspense>
              <SignInWithEmail />
            </Suspense>
          ),
        },
        {
          path: 'auth/google',
          element: (
            <Suspense>
              <WithGoogle />
            </Suspense>
          ),
        },
        {
          path: 'auth/facebook',
          element: (
            <Suspense>
              <WithFacebook />
            </Suspense>
          ),
        },
        {
          path: 'auth/sign-up/success',
          element: (
            <Suspense>
              <SignUpSuccess />
            </Suspense>
          ),
        },
      ],
    },
    {
      path: 'auth/:provider/redirect',
      element: (
        <Suspense>
          <OauthRedirect />
        </Suspense>
      ),
    },
    {
      path: 'auth/sign-out',
      element: (
        <Suspense>
          <SignOut />
        </Suspense>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouterProvider;
