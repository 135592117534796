import { sleep } from '@/lib/utils';
import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// Request interceptor to snake case the request data
apiClient.interceptors.request.use(
  (request) => {
    if (request.data) {
      request.data = decamelizeKeys(request.data);
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response interceptor to camelCase the response data
apiClient.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.headers['content-type'] &&
      contentTypeIncludesJson(
        response.headers['content-type'] as string | string[] | undefined,
      )
    ) {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiClient.interceptors.response.use(async (response) => {
  if (response.config.url && response.config.url.includes('auth')) return response;

  // add artificial delay for dev env
  if (import.meta.env.DEV && import.meta.env.VITE_API_ARTIFICIAL_DELAY) {
    await sleep(import.meta.env.VITE_API_ARTIFICIAL_DELAY);
  }
  return response;
});

function contentTypeIncludesJson(contentType: string | string[] | undefined) {
  return contentType && (contentType as string).includes('application/json');
}

export default apiClient;
