import AppRouterProvider from '@/providers/AppRouterProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from '@/providers/AuthProvider';
import { Toaster } from 'sonner';

function App() {
  return (
    <QueryClientProvider client={new QueryClient()}>
      <AuthProvider>
        <AppRouterProvider />
      </AuthProvider>
      <Toaster closeButton richColors duration={3000} />
    </QueryClientProvider>
  );
}

export default App;
