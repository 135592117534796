import { FC, ReactNode, createContext } from 'react';
import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query';
import { Session, getSession, signOut as signOutRequest } from '@/api/auth';
import FullScreenLoading from '@/components/ui/FullScreenLoading';
import { useLocalStorage } from 'usehooks-ts';
import { NavigateFunction } from 'react-router-dom';

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export type RedirectBackLocation = {
  path: string;
  host: 'app' | 'backend';
};

export type AuthContextType = {
  session: Session;
  signOut: () => void;
  refreshSession: () => Promise<UseQueryResult>;
  fetchingSession: boolean;
  setRedirectBackLocation: (redirectLocation: RedirectBackLocation | undefined) => void;
  redirectBackToLocation: (navigate: NavigateFunction) => void;
};

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [redirectBackLocation, setRedirectBackLocation] = useLocalStorage<
    RedirectBackLocation | undefined
  >('memoorias-redirect-to', undefined);

  const {
    data: session,
    isLoading: fetchingSession,
    refetch: refreshSession,
  } = useQuery({
    queryKey: ['session'],
    queryFn: getSession,
    staleTime: Infinity,
  });

  const { mutate: signOut } = useMutation({
    mutationKey: ['signOut'],
    mutationFn: signOutRequest,
    onSuccess: () => {
      // Redirect to the backend page
      window.location.href = import.meta.env.VITE_BACKEND_ENDPOINT;
    },
  });

  if (fetchingSession || !session) {
    return <FullScreenLoading />;
  }

  function redirectBackToLocation(navigate: NavigateFunction) {
    const path = redirectBackLocation?.path ?? '/memoories';
    const host = redirectBackLocation?.host;
    localStorage.removeItem('memoorias-redirect-to');

    if (host === 'backend') {
      window.location.replace(`${import.meta.env.VITE_BACKEND_ENDPOINT}${path}`);
    } else {
      navigate(path);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        session,
        signOut,
        refreshSession,
        fetchingSession,
        setRedirectBackLocation,
        redirectBackToLocation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
